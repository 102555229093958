import React from "react";
import MainMenu from "./MainMenu";
import Home from "./home";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

class App extends React.Component {
  render() {
    return (
      <Router>
        <div className="ui container" style={{ marginTop: "10px" }}>
          <MainMenu></MainMenu>
          <Switch>
            <Route exact path="/" component={Home}></Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
