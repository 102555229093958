import React, { Component } from "react";
import { Menu, Label } from "semantic-ui-react";
import { Link } from "react-router-dom";


export default class MainMenu extends Component {
  state = { activeItem: "home", countActive: 0, countCart: 0, countSold: 0 };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
    // this.retrieveData();
  };

  retrieveData() {
    // lotto.get("/tickets/0").then(({ data }) => {
    //   this.setState({ countActive: data.count });
    // });

    // lotto.get("/tickets/1").then(({ data }) => {
    //   this.setState({ countCart: data.count });
    // });

    // lotto.get("/tickets/2").then(({ data }) => {
    //   this.setState({ countSold: data.count });
    // });
  }

  componentDidMount() {
    this.retrieveData();
  }

  render() {
    const { activeItem, countActive, countCart, countSold } = this.state;

    return (
      <div>
        <Menu pointing>
          <Menu.Item
            name="home"
            active={activeItem === "home"}
            onClick={this.handleItemClick}
            as={Link}
            to="/"
          >
            H<Label>{countActive}</Label>
          </Menu.Item>
 
        </Menu>
      </div>
    );
  }
}
