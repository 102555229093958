import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";

import "semantic-ui-css/semantic.css";

// import "semantic-ui-css/semantic.js";

ReactDOM.render(<App />, document.querySelector("#root"));
// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>
// );
