import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import _ from "lodash";
import glo from "../../api/glo";

const mock = [
  { lottery_num: "345182" },
  { lottery_num: "015083" },
  { lottery_num: "172284" },
  { lottery_num: "573235" },
  { lottery_num: "554186" },
  { lottery_num: "204887" },
  { lottery_num: "913288" },
  { lottery_num: "097989" },
  { lottery_num: "274290" },
  { lottery_num: "746891" },
  { lottery_num: "277497" },
  { lottery_num: "720593" },
  { lottery_num: "454294" },
];

const Home = () => {
  const [term, setTerm] = useState(JSON.stringify(mock));
  const [period, setPeriod] = useState("2022-06-01");

  const callApi = (numbers) => {
    let params = { period_date: period, number: numbers };
    return glo.post("/checking/getcheckLotteryResult", params);
  };

  const onButtonClick = async () => {
    let numbers = JSON.parse(term);
    console.log(numbers);

    const chunks = _.chunk(numbers, 10);
    const promises = await chunks.map(callApi);
    Promise.all(promises).then((items) => {
      console.log(items);
    });
  };

  return (
    <Form>
      <Form.Input
        fluid
        label="Date"
        value={period}
        onChange={(e) => setTerm(e.target.value)}
      />
      <Form.TextArea
        label="tickets"
        value={term}
        onChange={(e) => setTerm(e.target.value)}
      />
      <Form.Button onClick={onButtonClick}>Submit</Form.Button>
    </Form>
  );
};

export default Home;
